<template>
  <v-container
    fluid
    style="max-width: 1000px;"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <v-card
          flat
          outlined
        >
          <v-card-title class="my-2">
            <span class="font-weight-bold">
              タイムライン
            </span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-timeline dense>
              <v-timeline-item
                v-for="(data, i) in tlData"
                :key="i"
                :color=colorByDataExist(data)
              >
                <span
                  v-if="monthConvertedFormat(data.month)==1"
                  class="font-weight-bold text-h2"
                >
                  <v-divider class="my-4" />
                  {{ yearConvertedFormat(data.month) }}年
                </span>
                <v-card
                  class="elevation-2"
                  min-height="140"
                >
                  <v-toolbar
                    :color=colorByDataExist(data)
                    dark
                    flat
                    height="40"
                  >
                    <strong class="text-h3">{{ monthConvertedFormat(data.month) }}月</strong>
                  </v-toolbar>
                  <v-card-text
                    v-if="data.content.length"
                    class="py-2"
                  >
                    <div
                      v-for="(content, j) in data.content"
                      :key="j"
                      class="mb-2"
                    >
                      <strong>{{ content.name }}</strong>
                      <div class="text-caption">
                        {{ content.subname }}
                      </div>
                    </div>
                  </v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    data: () => ({
      studyData: [],
      shikakuData: [],
      tlData: [],
    }),
    computed: {
      currentYear () {
        return new Date().getFullYear()
      },
    },
    created () {
      this.arrangeStudyData()
      this.arrangeShikakuData()
      this.createTlData()
    },
    methods: {
      createTlData () {
        for (let month = 0; month < 24; month++) {
          const formattedDate = new Date(this.currentYear, month + 1).toISOString().substr(0, 7)
          var list = []
          for (let c = 0; c < this.studyData.length; c++) {
            if (this.studyData[c].date) {
              if (this.studyData[c].date.indexOf(formattedDate) !== -1) {
                list.push(this.studyData[c])
              }
            }
          }
          for (let d = 0; d < this.shikakuData.length; d++) {
            if (this.shikakuData[d].date) {
              if (this.shikakuData[d].date.indexOf(formattedDate) !== -1) {
                list.push(this.shikakuData[d])
              }
            }
          }
          const dstData = {
            month: formattedDate,
            content: list,
          }
          this.tlData.push(dstData)
        }
      },
      arrangeStudyData () {
        const studyData = []
        var studyList = this.$store.state.user.studyList
        for (let i = 0; i < studyList.length; i++) {
          var name = studyList[i].name
          var date = studyList[i].shikenbi
          studyData.push({
            name: name,
            subname: '試験日',
            date: date,
          })
          name = studyList[i].name
          date = studyList[i].kekkahappyou
          studyData.push({
            name: name,
            subname: '結果発表',
            date: date,
          })
          this.studyData = studyData
        }
      },
      arrangeShikakuData () {
        const shikakuData = []
        var shikakuList = this.$store.state.user.shikakuList
        for (let i = 0; i < shikakuList.length; i++) {
          /* 取得日 */
          var name = shikakuList[i].name
          var date = shikakuList[i].shutokubi
          shikakuData.push({
            name: name,
            subname: '取得日',
            date: date,
          })
          /* 有効期限 */
          name = shikakuList[i].name
          date = shikakuList[i].yuukoukigen
          shikakuData.push({
            name: name,
            subname: '有効期限',
            date: date,
          })
          /* 更新期限 */
          name = shikakuList[i].name
          date = shikakuList[i].koushinkigen
          shikakuData.push({
            name: name,
            subname: '更新期限',
            date: date,
          })
        }
        this.shikakuData = shikakuData
      },
      monthConvertedFormat (yearAndMonth) {
        var dateObject = new Date(yearAndMonth)
        return dateObject.getMonth() + 1
      },
      yearConvertedFormat (yearAndMonth) {
        var dateObject = new Date(yearAndMonth)
        return dateObject.getFullYear()
      },
      colorByDataExist (data) {
        if (data.content.length) {
          return 'red lighten-2'
        } else {
          return 'grey'
        }
      },
    },
  }
</script>

<style>
.nobr {
  display: inline-block;
}

/* Smart Phone */
@media screen and (max-width: 479px) {
  h2 {
     font-size: 36px;
  }
}
</style>
